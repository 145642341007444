import React from 'react'
import PropTypes from 'prop-types'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import classNames from 'classnames'
import Fade from 'react-reveal/Fade'

import styles from './wineList.module.css'

import Image from './image'
import BuyButton from './buyButton'

const WineList = ({ wines }) => {
  if (!wines.totalCount) {
    return null
  }

  return (
    <section aria-label="KRIS Wine Offerings" className={styles.root}>
      <div className={styles.wrapper}>
        <ol className={styles.list}>
          {wines.edges.map(({ node }, index) => {
            const { id, fields, frontmatter } = node

            return (
              <Fade delay={250 * (index + 1)} key={id}>
                <li className={styles.item}>
                  <AniLink
                    to={fields.slug}
                    paintDrip
                    hex={frontmatter.featured_color}
                  >
                    {frontmatter.featured_image && (
                      <Image
                        node={frontmatter.featured_image.src}
                        alt={frontmatter.featured_image.alt}
                      />
                    )}
                  </AniLink>
                  <div className={styles.description} tabIndex={0}>
                    <AniLink
                      to={fields.slug}
                      paintDrip
                      hex={frontmatter.featured_color}
                    >
                      <h2 className={classNames('title', styles.title)}>
                        {frontmatter.title}
                      </h2>
                    </AniLink>
                    <h3
                      className={classNames(
                        'subtitle subtitle--small',
                        styles.origin
                      )}
                    >
                      {frontmatter.origin}
                    </h3>
                    <BuyButton node={node} />
                    <br />
                    <AniLink
                      to={fields.slug}
                      paintDrip
                      hex={frontmatter.featured_color}
                      className={styles.link}
                    >
                      More Information
                      <br /> about {frontmatter.title}
                    </AniLink>
                  </div>
                </li>
              </Fade>
            )
          })}
        </ol>
      </div>
    </section>
  )
}

WineList.propTypes = {
  wines: PropTypes.object.isRequired,
}

export default WineList
