import React, { Component } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import WineList from '../components/wineList'
// import WineReviews from '../components/wineReviews'

class Template extends Component {
  render() {
    const page = this.props.data.page
    const { frontmatter } = page

    return (
      <Layout>
        <SEO
          title={frontmatter.title}
          seoTitle={frontmatter.seo_title}
          description={frontmatter.seo_description}
        />
        <h1 className="visuallyhidden">{frontmatter.title}</h1>
        <WineList wines={this.props.data.wines} />
        {/* <WineReviews /> */}
      </Layout>
    )
  }
}

export default Template

export const query = graphql`
  query($slug: String!) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
      }
    }
    wines: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "wine" } } }
      sort: { fields: [frontmatter___sort], order: ASC }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            seo_title
            seo_description
            origin
            featured_image {
              src {
                publicURL
                childImageSharp {
                  fixed(width: 250) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              alt
            }
            featured_color
            purchase_links {
              label
              url
            }
          }
        }
      }
    }
  }
`
